import React from 'react'
// import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/common/Hero'
import FeeSection from '../sections/landing1/FeeSection'
import { Section, Title, Text, Box, Button } from '../components/Core'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { theme } from '../utils'
import helloCrypto from '../assets/image/svg/helloCrypto.svg'
import portfolioMgmt from '../assets/image/svg/portfolioMgmt.svg'
import barGraphLight from '../assets/image/svg/barGraph.svg'
import barGraphDark from '../assets/image/svg/barGraphDark.svg'
import MetaData from '../components/MetaData' 
import Link from '../components/Link'


import styled from 'styled-components'

const ImageShadow = styled(Box)`
  img {
    border-radius: 0px;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 52px 42px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 52px 42px ${theme.colors.shadow}`});
  }
`

const HelloCrypto = () => {
  const { t, ready } = useTranslation(['helloCrypto'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const sectionBackgroundDark = userDarkMode
    ? theme.colors.black
    : theme.colors.lightGray


  const customFeeSection = {
    leftCol: '3',
    rightCol: '8',
    emailInput: false,
    background: theme.colors.bgDarkGray,
    title: t('sameCoins'),
    firstP: t('whyPay'),
  }

  const textColor = userDarkMode ? theme.colors.light : theme.colors.dark


  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Hero
          bg={sectionBackgroundDark}
          title={t('title')}
          firstP={t('subtitle')}
          externalLink={true}
          externalText={t('trial')}
          externalUrl={process.env.LS_SIGNUP}
          img={true}
          imgSrc={userDarkMode ? barGraphDark : barGraphLight}
        />

        {/* Same coins : Section */}
        <Section bg={userDarkMode ? theme.colors.dark : theme.colors.light}>
          <Container>
            <Row className="pb-4">
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                  {t('trade')}
                </Title>
              </Col>

              <Col lg="8">
                <Text color={textColor} mb={24}>
                    {t('elevate')}
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>

        

        {/* Real-time : Section */}
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row>
              <Col lg="7">
                <Title variant="highlight" color={textColor}>
                  {t('control')}
                </Title>
                <Text color={textColor} className="mb-4">
                    {t('cryptoSYNC')}
                </Text>
                <Link to={`../portfolio-management/`}>
                  <Button>{t('cryptoSYNCbtn')}</Button>
                </Link>
              </Col>
              <Col lg="5">
                <ImageShadow
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                >
                  <img
                    width="100%"
                    height="auto"
                    alt=""
                    className="img-fluid"
                    src={portfolioMgmt}
                  />
                </ImageShadow>
              </Col>
            </Row>
          </Container>
        </Section>

        {/* No Monthly : Section */}
        <Section bg={userDarkMode ? theme.colors.dark : theme.colors.light}>
          <Container>
            <Row>
              <Col lg="4">
                <Title variant="highlight" color={textColor}>
                  {t('yourNext')}
                </Title>
              </Col>

              <Col lg="8">
                <Text color={textColor} mb={24}>
                    {t('tradeWith')}
                </Text>
                <Text color={textColor} mb={24}>
                    {t('mostPopular')}
                </Text>
                <Text color={textColor} mb={24} variant="strong">
                    {t('takeAdvantage')}
                </Text>
                <Button className="mt-3">
                    <a target="_blank" href={process.env.LS_SIGNUP}>
                        <Text color="#FAFAFA">
                            {t('unlock')}
                        </Text>
                    </a>
                </Button>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default HelloCrypto
